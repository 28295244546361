<template>
  <div>
    <a-modal v-model="modalVisible" title="新增" :width="900" :centered="true" :body-style="modalBodyStyle">
<!--      <a-tabs type="card">-->
<!--        <a-tab-pane key="1" tab="申请信息">-->
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm('1')">提交</a-button>
        <a-button v-if="showType=='check'" @click="confirm('3')">审核不通过</a-button>
        <a-button v-if="showType=='check'" type="primary" @click="confirm('2')">审核通过</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formDatas" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}">
          <h3 style="font-weight: bold; color: #333;text-align: center;font-size: 25px">问题整改具体内容</h3>
          <a-form-model-item label="问题整改发起人" prop="initiator">
            <a-input v-model.trim="formDatas.initiator" />
          </a-form-model-item>
          <a-form-model-item label="受理人" prop="acceptor">
            <a-input v-model.trim="formDatas.acceptor" />
          </a-form-model-item>
          <a-form-model-item label="待整改单位" prop="depwaitrect">
            <a-input v-model.trim="formDatas.depwaitrect" />
          </a-form-model-item>
          <a-form-model-item label="待整改电梯" prop="liftwaitrect">
            <a-input v-model.trim="formDatas.liftwaitrect" />
          </a-form-model-item>
          <a-form-model-item label="整改具体事项" prop="rectdetail">
            <a-textarea v-model.trim="formDatas.rectdetail" />
          </a-form-model-item>
          <a-form-model-item label="整改时限" prop="recttime">
            <a-date-picker v-model="formDatas.recttime"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="是否已回复" prop="isreply">
            <a-radio-group v-model="formDatas.isreply">
              <a-radio :value="0">是</a-radio>
              <a-radio :value="1">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
      </a-form-model>
<!--      </a-tab-pane>-->
<!--      <a-tab-pane v-if="" key="2" tab="流程追踪">-->
      <h3 style="font-weight: bold; color: #333;text-align: center;font-size: 25px">流程追踪</h3>
        <a-timeline style="margin-left: 10%">
          <a-timeline-item v-for="(item, index) in wrokflow" :key="index" :color="index<wrokflowInex?'green':'gray'">
            <div>操作步骤: {{liftapplicationsteps[item.flowstep]}}</div>
            <div>操作人员: {{item.person}}</div>
            <div>完成时间: {{moment(item.finishtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")}}</div>
          </a-timeline-item>
        </a-timeline>
<!--      </a-tab-pane>-->
<!--      </a-tabs>-->
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import liftapplicationsteps from "@/json/liftapplicationsteps";
import {findWorkflowByorderId} from "A/wbgl";
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      liftapplicationsteps,
      modalBodyStyle: {
        height: '600px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        initiator:'',
        acceptor:'',
        depwaitrect:'',
        liftwaitrect:'',
        rectdetail:'',
        recttime:'',
        isreply:'',
      },
      //这里面的数据属于必填项
      formRules: {
      },
      userloginurlList: [],
      wrokflow: [
        {
          flowstep:'10',
          person:'超级管理员',
          finishtime:'20211206160650'
        },
        {
          flowstep:'20',
          person:'超级管理员',
          finishtime:'20211206161650'
        },
        {
          flowstep:'40',
          person:'超级管理员',
          reason:'未按承诺实施维保',
          finishtime:'20211206163650'
        },
      ],
      wrokflowInex:20,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas(){

    },
    getWorkFlow() {
      let params = {
        maintenancorderid: this.detailData.maintenancorderid
      };
      findWorkflowByorderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.wrokflow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formDatas.processstep && this.wrokflow && this.wrokflow.length) {
        this.wrokflow.forEach((item, index) => {
          if(item.flowstep == this.formDatas.processstep) {
            this.wrokflowInex = index;
          }
        })
      }
    },
    confirm(checkstatus) {
    },
    userloginurlUploaded(info) {
      let fileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.urlPath;
          // let nameIndex = file.response.urlPath.lastIndexOf('/');
          // if(nameIndex < 0) {
          //   nameIndex = file.response.urlPath.lastIndexOf('\\');
          // }
          // let fileName = file.response.urlPath.substr(nameIndex+1);
          // file.name = fileName
          this.formDatas.userloginurl = file.response.urlPath;
        }
        return file;
      });
      this.userloginurlList = fileList;
    },
  }
}
</script>